import { tranferConstants } from '../constants'

const defaultState = {
    data: [{}],
  }

export default function tranfer(state = defaultState, action){
    switch(action.type) {
        case tranferConstants.TRANFER_SELECTED:
            return { ...state, ...action }
        default:
            return { ...state }
    }
}