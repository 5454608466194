import { langConstants } from "../constants";

const defaultState = {
  locale: "en",
};

export default function language(state = defaultState, action) {
  switch (action.type) {
    case langConstants.CHANGE_LANG:
      return { ...state, ...action };
    default:
      return state;
  }
}
